import _ from "lodash/fp";
import React from "react";
import { connect } from "react-redux";
import { useLocation, Redirect } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Accordion, Header, Icon, Image, Divider } from "semantic-ui-react";
import {
  AuthMethodsLoader,
  OidcLoginButton,
  Auth0LoginButton
} from "@truedat/auth/components";
import { Alert } from "@truedat/core/components";
import { SEARCH } from "@truedat/core/routes";
import sofiaLogo from "assets/logo_sofia.png";
import naturgyLogo from "assets/naturgy.png";
import backgroundImage from "assets/background_sofia.png";
import LoginForm from "./LoginForm";

const LoginHeader = () => (
  <Header
    inverted
    as="h1"
    icon
    textAlign="center"
    style={{ marginBottom: "50px" }}
  >
    <Header.Content>
      <Image src={sofiaLogo} />
    </Header.Content>
  </Header>
);

const mainWrapper = {
  backgroundColor: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  position: "absolute",
  left: 0,
  right: 0,
  backgroundImage: "url(" + backgroundImage + ")",
  backgroundSize: "100% 100%",
  backgroundPosition: "0px 10px",
  backgroundRepeat: "no-repeat"
};

const imageWrapper = {
  position: "absolute",
  top: "18px",
  right: "18px"
};

const viewStyle = {
  width: "310px"
};

class LoginView extends React.Component {
  constructor(props) {
    super(props);

    this.state = { formVisible: false };
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle(e) {
    e.preventDefault();
    const { formVisible } = this.state;
    this.setState({ formVisible: !formVisible });
  }

  render() {
    const { formVisible } = this.state;
    return (
      <>
        <AuthMethodsLoader />
        <LoginHeader />
        <Auth0LoginButton icon="user icon" />
        <Alert />
        <Accordion>
          <Accordion.Title active={formVisible} onClick={this.handleToggle}>
            <Icon name="dropdown" />
            <FormattedMessage
              id="login.form.aditionalaccess"
              defaultMessage="Alternative login"
            />
          </Accordion.Title>
        </Accordion>
        {formVisible && (
          <div>
            <LoginForm />
            <Divider />
            <OidcLoginButton color="green" icon="google icon" />
          </div>
        )}
      </>
    );
  }
}

const resolveRoute = defaultRoute =>
  _.cond([
    [_.pathEq("state.from.pathname", "/"), () => defaultRoute],
    [_.has("state.from"), _.path("state.from")],
    [_.stubTrue, () => defaultRoute]
  ]);

const LoginOrRedirect = ({ token }) => {
  const location = useLocation();
  if (token) {
    const to = resolveRoute(SEARCH)(location);
    return <Redirect to={to} />;
  } else {
    return (
      <div style={mainWrapper}>
        <figure style={imageWrapper}>
          <Image src={naturgyLogo} style={{ margin: "0 auto" }} />
        </figure>
        <div style={viewStyle}>
          <LoginView />
        </div>
      </div>
    );
  }
};

const mapStateToProps = ({ authentication }) => ({
  token: authentication.token
});

export default connect(mapStateToProps)(LoginOrRedirect);
